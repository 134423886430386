import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

const MobileContext = createContext();

export const useMobile = () => useContext(MobileContext);

export const MobileProvider = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const isMobileDevice = () => {
    if (typeof navigator === 'undefined') return false;
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  };

  const isTouchDevice = () => {
    if (typeof window === 'undefined') return false;
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const [isMobile, setIsMobile] = useState(() => {
    const mobileDetected = isMobileDevice() || isTouchDevice();
    return mobileDetected || isSmallScreen;
  });

  useEffect(() => {
    const handleResize = () => {
      const mobileDetected = isMobileDevice() || isTouchDevice();
      setIsMobile(mobileDetected || isSmallScreen);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isSmallScreen]);

  return (
    <MobileContext.Provider value={isMobile}>
      {children}
    </MobileContext.Provider>
  );
};

MobileProvider.propTypes = {
  children: PropTypes.node.isRequired,
};