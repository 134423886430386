import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MobileProvider } from 'MobileContext';
import './global.css';

const Routes = lazy(() => import('./Routes'));

const App = () => {
  useEffect(() => {
    import('slick-carousel/slick/slick.css');
    import('slick-carousel/slick/slick-theme.css');
    import('aos/dist/aos.css');
    import('react-18-image-lightbox/style.css');
  }, []);

  return (
    <MobileProvider>
      <BrowserRouter>
        <Suspense>
          <Routes />
        </Suspense>
      </BrowserRouter>
    </MobileProvider>
  );
};

export default App;